import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, RouterEvent, ActivationEnd, ChildActivationEnd } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'projects/services/src/lib/authentication.service';
import { OrderService } from 'projects/services/src/lib/order.service';
import { Order, OrderConfirmation, OrderStatus } from 'projects/common/src/lib/order';
import { OrderTypeEnum } from 'projects/common/src/lib/ordertype';
import { MerchantService } from 'projects/services/src/lib/merchant.service';
import { Merchant } from 'projects/common/src/lib/merchant';
import { MerchantSettings } from 'projects/common/src/lib/merchant-settings';
import { first } from 'rxjs/operators';
import { Transaction } from 'projects/common/src/lib/transaction';
import { Payment } from 'projects/common/src/lib/payment';
import { EtcPaymentResponse } from 'projects/common/src/lib/etc-transaction-response';
import { Observable, Subscription, Subject } from 'rxjs';
import { filter, takeUntil, map } from 'rxjs/operators';
import { Regex } from 'projects/common/src/lib/regex';
import { CardInfo } from 'projects/common/src/lib/cardinfo';
import ccType from 'credit-card-type';
import * as cardValidator from 'card-validator';
import { Address } from 'projects/common/src/lib/address';
import { GoogleApiService } from 'projects/services/src/lib/google-api.service';
import { MatStepper } from '@angular/material/stepper';
import { User } from 'projects/common/src/lib/user';
import { LogIn } from 'projects/common/src/lib/login';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Rating } from 'projects/common/src/lib/rating';
import { RatingsService } from 'projects/services/src/lib/ratings.service';
import { SmsService } from 'projects/services/src/lib/sms.service';
import { EmailService } from 'projects/services/src/lib/email.service';
import { ConfigService } from 'projects/services/src/lib/config.service';
import { EmailOrderTemplate } from 'projects/common/src/lib/email-order-template';
import { EmailOrderConfirmation } from 'projects/common/src/lib/email-order-confirmation';
import { Customer } from 'projects/common/src/lib/customer';
/* import { Item } from 'projects/common/src/lib/item'; */
/* import { GooglePay } from 'projects/common/src/lib/googlepay/google-pay';
import { SamsungPay } from 'projects/common/src/lib/samsungpay/samsung-pay'; */
import { ApplePay } from 'projects/common/src/lib/applepay/apple-pay-js';
import { v4 as uuid } from 'uuid';
import { LoggerService } from 'projects/services/src/lib/logger.service';
import { LogEntry } from 'projects/common/src/lib/log-entry';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

const moment = require('moment-timezone');

interface Window {
  innerWidth: number;
  innerHeight: number;
  location: any;
  ApplePaySession: any;
  PaymentRequest: any;    // samsung
}

declare var window: Window;

@Component({
  selector: 'app-scan-to-pay',
  templateUrl: './scan-to-pay.component.html',
  styleUrls: ['./scan-to-pay.component.scss']
})
export class ScanToPayComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('paymentEmail') paymentEmail: ElementRef;
  @ViewChild('stepper') qrStepper: MatStepper;

  order: Order;
  merchant: Merchant;
  brandname = 'pw';
  public orderSubscription: Subscription;
  public paymentSubscription: Subscription;
  public routeSubscription: Subscription;
  public emailSubscription: Subscription;
  public smsSubscription: Subscription;
  public merchantSmsSubscription: Subscription;
  private loggerSubscription: Subscription;

  public merchantNumber = '';
  public sid = '';
  public destroyed = new Subject<any>();
  public tipRatePct = 0.0;
  public tipAmount = '0.00';
  public customTip = 0.0;
  public customerServiceFeeAmount = 0.00;
  public deliveryFeeAmount = 0.00;
  public convenienceFeeAmount = 0.00;
  public totalAmount = '0.00';
  public isCustomTip = false;
  public regexList: Regex = new Regex();
  public cardInfo: CardInfo = new CardInfo();
  public payMethods: CardInfo[] = [];
  public selectedIndex = 0;
  public cardbrandlogo = '';
  public cardType = '';
  public paymentResponse: EtcPaymentResponse;
  public paymentError: any;
  public orderSubmitError: any;
  public processingPayment = false;
  public processingEmail = false;
  public processingSMS = false;
  private user: User;
  public ratingSubscription: Subscription;
  public disableRatings = false;
  public value = 0;
  private configSubscription: Subscription;
  orderConfig: any;
  baseUrl = 'https://www.deliverme.com';
  public emailSent = false;
  public smsSent = false;
  public orderDateTime = '';

  public ccRegex = {
    brand: '',
    minlength: 16,
    mincvv: 3,
    cvvmask: 999,
    ccmask: this.regexList.ccRegexList.common
  };

  cvvMask = 'XXX';
  ccMask = 'XXXXXXXXXXXX0000';

  /*   public googlePay: GooglePay = new GooglePay();
    public samsungPay: SamsungPay = new SamsungPay(); */
  public applePay: ApplePay;
  applePayConfig: any = {};
  disableApplePay = false;
  applePayIsSupported = false;

  // public formControls: any;
  address: Address = new Address();
  matches: [];
  searchTimer: any;
  loading = false;

  gratuityFormGroup = new UntypedFormGroup({
    tipRate: new UntypedFormControl(this.tipRatePct, [Validators.required]),
    tip: new UntypedFormControl(this.tipAmount, [Validators.required]),
    customTipAmount: new UntypedFormControl(this.tipAmount, [Validators.required]),
    customerServiceFee: new UntypedFormControl(this.customerServiceFeeAmount, []),
    deliveryFeeAmount: new UntypedFormControl(this.deliveryFeeAmount, []),
    convenienceFeeAmount: new UntypedFormControl(this.convenienceFeeAmount, [])
  });

  get tipRate(): any { return this.gratuityFormGroup.get('tipRate'); }
  get tip(): any { return this.gratuityFormGroup.get('tip'); }
  get customTipAmount(): any { return this.gratuityFormGroup.get('customTipAmount'); }
  get customerServiceFee(): any { return this.gratuityFormGroup.get('customerServiceFee'); }
  get deliveryFee(): any { return this.gratuityFormGroup.get('deliveryFeeAmount'); }
  get convenienceFee(): any { return this.gratuityFormGroup.get('convenienceFeeAmount'); }

  public billingFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl(this.cardInfo.name, [Validators.required, this.invalidName()]),
    firstname: new UntypedFormControl('', []),
    lastname: new UntypedFormControl('', [Validators.required, Validators.pattern(this.regexList.email)]),
    email: new UntypedFormControl('', [Validators.required, this.validateEmail()]),
    phone: new UntypedFormControl('', [this.phoneValidator()]),
    cardnumber: new UntypedFormControl(this.cardInfo.cardnumber, [Validators.required, Validators.minLength(this.ccRegex.minlength), Validators.maxLength(22), this._invalidCardNumberValidator()]),
    cvv: new UntypedFormControl(this.cardInfo.cvv, [Validators.required, Validators.minLength(this.ccRegex.mincvv), Validators.maxLength(this.ccRegex.mincvv)]),
    expdate: new UntypedFormControl(this.cardInfo.expdate, [Validators.required, this._invalidExpDateValidator()]),
    cardbrand: new UntypedFormControl(this.cardInfo.cardbrand, []),
    address: new UntypedFormControl('', []),
    addressF: new UntypedFormControl('', []),
    street1: new UntypedFormControl(this.cardInfo.street1, [Validators.required, this.validateStreet()]),
    city: new UntypedFormControl(this.cardInfo.city, [Validators.required, this.validateCity()]),
    state: new UntypedFormControl(this.cardInfo.state, [Validators.required]),
    zip: new UntypedFormControl(this.cardInfo.zip, [Validators.required, Validators.minLength(5), Validators.maxLength(10)]),
    country: new UntypedFormControl(this.cardInfo.country, [Validators.required]),
  });

  get name(): any { return this.billingFormGroup.get('name'); }
  get cardnumber(): any { return this.billingFormGroup.get('cardnumber'); }
  get cvv(): any { return this.billingFormGroup.get('cvv'); }
  get expdate(): any { return this.billingFormGroup.get('expdate'); }
  get cardbrand(): any { return this.billingFormGroup.get('cardbrand'); }
  get firstname(): any { return this.billingFormGroup.get('firstname'); }
  get lastname(): any { return this.billingFormGroup.get('lastname'); }
  get phone(): any { return this.billingFormGroup.get('phone'); }
  get email(): any { return this.billingFormGroup.get('email'); }
  get addressF(): any { return this.billingFormGroup.get('addressF') ? this.billingFormGroup.get('addressF') : ''; }
  get street1(): any { return this.billingFormGroup.get('street1') ? this.billingFormGroup.get('street1') : ''; }
  get city(): any { return this.billingFormGroup.get('city') || ''; }
  get state(): any { return this.billingFormGroup.get('state') || ''; }
  get zip(): any { return this.billingFormGroup.get('zip') || ''; }
  get country(): any { return this.billingFormGroup.get('country') || ''; }

  public states: string[] = [
    'AK',
    'AL',
    'AR',
    'AZ',
    'CA',
    'CO',
    'CT',
    'DE',
    'FL',
    'GA',
    'HI',
    'IA',
    'ID',
    'IL',
    'IN',
    'KS',
    'KY',
    'LA',
    'MA',
    'MD',
    'ME',
    'MI',
    'MN',
    'MO',
    'MS',
    'MT',
    'NC',
    'ND',
    'NE',
    'NH',
    'NJ',
    'NM',
    'NV',
    'NY',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VA',
    'VT',
    'WA',
    'WI',
    'WV',
    'WY',
    'NL',
    'PE',
    'NS',
    'NB',
    'QC',
    'ON',
    'MB',
    'SK',
    'AB',
    'BC',
    'YT',
    'NT',
    'NU'
  ];

  public screenWidth: number = 0;
  public screenHeight: number = 0;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public orderService: OrderService,
    public merchantService: MerchantService,
    private formBuilder: UntypedFormBuilder,
    private authSvc: AuthenticationService,
    private localStorage: StorageMap,
    private ratingsService: RatingsService,
    private smsSvc: SmsService,
    private emailService: EmailService,
    private google: GoogleApiService,
    private configService: ConfigService,
    private loggerService: LoggerService,
    private confirmDialog: MatDialog,
    private http: HttpClient) {

    try {

      if (this.routeSubscription) {
        this.routeSubscription.unsubscribe();
      }

      this.routeSubscription = this.router.events.pipe(
        filter((event: RouterEvent) => event instanceof ChildActivationEnd),
        takeUntil(this.destroyed)
      ).subscribe(async (e: any) => {
        if (e instanceof ChildActivationEnd) {
          if (e.snapshot.url && e.snapshot.url.length > 1) {
            this.merchantNumber = e.snapshot.url[1].path;
            this.sid = e.snapshot.url[2].path;

            const guestuser: any = await this.GuestLogin().catch((error: any) => {

            });

            this.user.access_token = guestuser.access_token;

            this.configSubscription = this.configService.getConfig('applepay', guestuser.access_token).subscribe((config: any) => {
              this.applePayConfig = config;

              if (window.ApplePaySession) {
                this.order.businessName = this.merchant.businessName;
                this.order.ordertype = OrderTypeEnum.DineIn;
                this.order.taxrate = this.merchant.settings.taxrate ? this.merchant.settings.taxrate : 0.00;

                if (!this.order.customerInformation) {
                  this.order.customerInformation = new Customer();
                  this.order.customerInformation.country = 'USA';
                }

                this.applePay = new ApplePay(this.order, this.http, this.orderService, this.applePayConfig);
                this.applePay.onApplePayLoaded();

                this.applePayIsSupported = this.applePay.isSupported;
              }

            }, (error: any) => {
              console.log(error.message);
            });
          }
        }
      });

    } catch (error) {
      console.log(error);
    }

  }

  @HostListener('window:resize', ['event'])
  onResize(event) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  async ngOnInit(): Promise<void> {

    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;

    this.baseUrl = window.location.origin;

    const guestuser: any = await this.GuestLogin().catch((error: any) => {
      alert(error.message);
    });

    this.user.access_token = guestuser.access_token;

    this.configSubscription = this.configService.getConfig('api/orders', this.user.access_token).subscribe((config: any) => {
      this.orderConfig = config;

      if (this.orderConfig && this.orderConfig.url) {
        this.baseUrl = this.orderConfig.url;
      }

      this.gratuityFormGroup = new UntypedFormGroup({
        tipRate: new UntypedFormControl(this.tipRatePct, [Validators.required]),
        tip: new UntypedFormControl(this.tipAmount, [Validators.required]),
        customTipAmount: new UntypedFormControl(this.tipAmount, [Validators.required]),
        customerServiceFee: new UntypedFormControl(this.customerServiceFeeAmount, []),
        deliveryFeeAmount: new UntypedFormControl(this.deliveryFeeAmount, []),
        convenienceFeeAmount: new UntypedFormControl(this.convenienceFeeAmount, [])
      });

      this.billingFormGroup = new UntypedFormGroup({
        name: new UntypedFormControl(this.cardInfo.name, [Validators.required, this.invalidName()]),
        firstname: new UntypedFormControl('', []),
        lastname: new UntypedFormControl('', []),
        email: new UntypedFormControl('', [Validators.required, Validators.pattern(this.regexList.email)]),
        phone: new UntypedFormControl('', [this.phoneValidator()]),
        cardnumber: new UntypedFormControl(this.cardInfo.cardnumber, [Validators.required, Validators.minLength(this.ccRegex.minlength), Validators.maxLength(22), this._invalidCardNumberValidator()]),
        cvv: new UntypedFormControl(this.cardInfo.cvv, [Validators.required, Validators.minLength(this.ccRegex.mincvv), Validators.maxLength(this.ccRegex.mincvv)]),
        expdate: new UntypedFormControl(this.cardInfo.expdate, [Validators.required, this._invalidExpDateValidator()]),
        cardbrand: new UntypedFormControl(this.cardInfo.cardbrand, []),
        address: new UntypedFormControl('', []),
        addressF: new UntypedFormControl('', []),
        street1: new UntypedFormControl(this.cardInfo.street1, [Validators.required]),
        city: new UntypedFormControl(this.cardInfo.city, [Validators.required]),
        state: new UntypedFormControl(this.cardInfo.state, [Validators.required]),
        zip: new UntypedFormControl(this.cardInfo.zip, [Validators.required]),
        country: new UntypedFormControl(this.cardInfo.country, [Validators.required]),
      });

      this.route.paramMap.subscribe(async (params: any) => {

        this.merchantNumber = params.get('mid');
        this.sid = params.get('sid');

        if (this.merchantNumber && this.sid) {

          this.loading = true;

          this.order = await this.getOrder(this.merchantNumber, this.sid);

          if (this.order) {

            this.order.orderxref = this.sid;

            // console.log(this.order);

            if (this.order.paymethods.length > 0 || !this.order.orderno) {
              if (!this.applePayIsSupported) {
                this.qrStepper.selectedIndex = 3;
              } else {
                this.qrStepper.selectedIndex = 4;
              }

              for (let i = 0; i < 3; i++) {
                this.merchant = await this.getMerchant(this.merchantNumber);

                if (this.merchant && this.merchant.settings) {
                  break;
                }
              }

              this.loading = false;
            } else {

              this.updateTotal();

              // console.log('order', this.order);
              for (let i = 0; i < 3; i++) {
                this.merchant = await this.getMerchant(this.merchantNumber);

                if (this.merchant && this.merchant.settings) {
                  break;
                }
              }

              this.loading = false;

              this.qrStepper.selectedIndex = 1;
            }

            this.configSubscription = this.configService.getConfig('applepay', this.user.access_token).subscribe((appleConfig: any) => {
              this.applePayConfig = appleConfig;

              if (window.ApplePaySession) {
                this.order.useWalletShippingAddress = true;
                this.applePay = new ApplePay(this.order, this.http, this.orderService, this.applePayConfig);
                this.applePay.onApplePayLoaded();
                // alert('ApplePay loaded');
              }

            }, (error: any) => {
              console.log(error.message);
              alert(error.message);
            });

          } else {
            this.loading = false;
          }
        }
      });
    }, (error: any) => {
      this.loading = false;
      console.log(error.message);
      // alert('get order config error: ' + error.message);
    });
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
    if (this.orderSubscription) { this.orderSubscription.unsubscribe(); }
    if (this.paymentSubscription) { this.paymentSubscription.unsubscribe(); }
    if (this.routeSubscription) { this.routeSubscription.unsubscribe(); }
    if (this.emailSubscription) { this.emailSubscription.unsubscribe(); }
    if (this.smsSubscription) { this.smsSubscription.unsubscribe(); }
    if (this.merchantSmsSubscription) { this.merchantSmsSubscription.unsubscribe(); }
    if (this.loggerSubscription) { this.loggerSubscription.unsubscribe(); }
  }

  public onStepChange(event: any): void {
    // console.log(event);
    this.selectedIndex = event.selectedIndex;

    if (this.selectedIndex === 2) {
      if (window.ApplePaySession) {

        this.order.businessName = this.merchant.businessName;
        this.order.ordertype = OrderTypeEnum.DineIn;
        this.order.taxrate = this.merchant.settings.taxrate ? this.merchant.settings.taxrate : 0.00;

        if (!this.order.customerInformation) {
          this.order.customerInformation = new Customer();
          this.order.customerInformation.country = 'USA';
        }

        this.applePay = new ApplePay(this.order, this.http, this.orderService, this.applePayConfig);
        this.applePay.onApplePayLoaded();
        this.applePayIsSupported = this.applePay.isSupported;
      }
    }
  }

  public async getOrder(mid: string, sid: string): Promise<Order> {
    return new Promise((resolve, reject) => {

      let logEntry = new LogEntry();

      try {

        logEntry.entry.application = 'dm-ui';
        logEntry.entry.file = 'scan-to-pay.component.ts';
        logEntry.entry.method = 'getOrder';
        logEntry.entry.merchantNumber = this.merchantNumber;

        if (this.orderSubscription) {
          this.orderSubscription.unsubscribe();
        }

        if (this.loggerSubscription) {
          this.loggerSubscription.unsubscribe();
        }

        logEntry.entry.text = 'fetching scantopay order';

        this.loggerSubscription = this.loggerService.writeToLog(logEntry, this.user.access_token).subscribe((response: any) => {
        }, (error: any) => {
          alert("error fetching scantopay order: " + error.message);
          console.log('log service error: ' + error.message);
        });

        this.orderSubscription = this.orderService.getScanToPayOrder(mid, sid, this.user.access_token).subscribe((order: any) => {

          logEntry.entry.text = 'scantopay order: ' + JSON.stringify(order);

          this.loggerSubscription = this.loggerService.writeToLog(logEntry, this.user.access_token).subscribe((response: any) => {
          }, (error: any) => {
            alert(error.message);
            console.log('log service error: ' + error.message);
          });

          this.loggerSubscription.unsubscribe();

          order.serviceFee = order.serviceFee || order.ServiceFee;
          order.deliveryFee = order.deliveryfee ? order.deliveryfee : order.deliveryFee || 0;
          order.convenienceFee = order.ConvenienceFee ? order.ConvenienceFee : order.convenienceFee || 0;

          this.convenienceFee.value = order.convenienceFee;
          this.deliveryFee.value = order.deliveryfee || order.deliveryFee;

          if (order.totalAmount !== undefined) {
            this.totalAmount = order.totalAmount.toFixed(2) || order.total_amount.toFixed(2);
          } else {
            this.totalAmount = order.total_amount.toFixed(2) || order.totalAmount.toFixed(2);
          }

          if (order.CustomerServiceFeeOn === true) {
            if (order.CSFIsDollar === true) {
              this.customerServiceFee.value = order.CustomerServiceFee.toFixed(2);
            } else {
              this.customerServiceFee.value = (order.CustomerServiceFee || 0) * order.subtotal;
            }

            order.serviceFee = this.customerServiceFee.value;
          }

          if (order.items) {
            order.items.forEach((item) => {
              if (!item.labeledPrice) {
                item.labeledPrice = item.price.toFixed(2);
              }
            });
            resolve(order);
          } else {
            resolve(order);
          }

        }, (error) => {
          this.loading = false;

          let err: any;

          if (error.error) {
            err = error.error;
          } else {
            err = error;
          }

          logEntry.entry.application = 'dm-ui';
          logEntry.entry.file = 'scan-to-pay.component.ts';
          logEntry.entry.method = 'getOrder';
          logEntry.entry.merchantNumber = this.merchantNumber;

          logEntry.entry.error = 'scantopay order error: ' + err.message;
          logEntry.entryType = "Error";

          this.loggerSubscription = this.loggerService.writeToLog(logEntry, this.user.access_token).subscribe((response: any) => {
          }, (error: any) => {
            alert(error.message);
            console.log('log service error: ' + error.message);
          });

          this.loggerSubscription.unsubscribe();

          // alert('getScanToPay error: ' + error.message);
          reject(err);
        });
      } catch (error) {
        this.loading = false;
        reject(error);

        alert(error.message);
        logEntry.entry.application = 'dm-ui';
        logEntry.entry.file = 'scan-to-pay.component.ts';
        logEntry.entry.method = 'getOrder';
        logEntry.entry.merchantNumber = this.merchantNumber;

        logEntry.entry.error = 'scantopay order error: ' + error.message;
        logEntry.entryType = "Error";

        this.loggerSubscription = this.loggerService.writeToLog(logEntry, this.user.access_token).subscribe((response: any) => {
        }, (error: any) => {
          console.log('log service error: ' + error.message);
        });

        this.loggerSubscription.unsubscribe();
      }
    });
  }

  public async getMerchant(merchantNumber: string): Promise<Merchant> {
    return new Promise((resolve, reject) => {
      try {
        this.merchantService.search('m', merchantNumber).subscribe((result: any) => {
          // console.log(result);
          resolve(result[0]);
        }, (error) => {
          console.log(error);
          reject(error);
        });
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  public SetTip(value, type) {

    if (type === 'c') {
      this.isCustomTip = true;
    } else {
      this.isCustomTip = false;
      this.customTipAmount.value = 0.00;
      this.gratuityFormGroup.patchValue({ customTipAmount: 0.00 });
    }

    this.tipAmount = (this.order.subtotal * value).toFixed(2);
    this.order.tip = parseFloat(this.tipAmount);
    this.gratuityFormGroup.patchValue({ tipRate: parseFloat(value) });
    this.gratuityFormGroup.patchValue({ tip: this.tipAmount });

    this.updateTotal();
  }

  public updateTotal() {
    try {

      if (!this.tipAmount) {
        this.tipAmount = '0.00';
      }

      this.tipAmount = parseFloat(this.tipAmount).toFixed(2);

      let totalVal = 0.00;

      if (!this.order.convenienceFee) {
        this.order.convenienceFee = 0.00;
      }

      totalVal = this.order.subtotal + this.order.tax + parseFloat(this.order.serviceFee.toFixed(2)) + this.order.convenienceFee + this.order.deliveryFee;

      totalVal += parseFloat(this.tipAmount);
      this.totalAmount = (totalVal).toFixed(2);
      this.order.tip = parseFloat(this.tipAmount);
      this.order.totalAmount = parseFloat(this.totalAmount);
    } catch (error) {
      console.log(error.message);
    }
  }

  private invalidName(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      try {
        if (!this.billingFormGroup) { return null; }
        if (this.billingFormGroup.pristine && !this.billingFormGroup.dirty && !this.billingFormGroup.touched) { return null; }

        // remove emoji's
        this.name.value = this.name.value.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');

        if (this.name.value.split(' ').length < 2) {
          return { invalidName: { value: control.value } };
        } else {
          return null;
        }
      } catch (error) {
        console.log(error);
        return null;
      }
    };
  }

  private phoneValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      try {
        if (!this.billingFormGroup) { return null; }
        if (this.billingFormGroup.pristine && !this.billingFormGroup.dirty && !this.billingFormGroup.touched) { return null; }

        const regex = this.regexList.us_phone_regex;

        // remove emoji's
        const isValid = this.phone.value ? regex.test(this.phone.value) : true;

        if (!isValid) {
          return { invalidPhone: { value: control.value } };
        } else {
          return null;
        }
      } catch (error) {
        console.log(error);
        return null;
      }
    };
  }

  private _invalidCardNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      try {
        if (!this.billingFormGroup) { return null; }
        if (this.billingFormGroup.pristine && !this.billingFormGroup.dirty && !this.billingFormGroup.touched) { return null; }
        // remove emoji's
        this.cardnumber.value = this.cardnumber.value.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');

        const cardNumber: any = this.billingFormGroup.get('cardnumber').value.replace(this.regexList.fixCCstring, '');
        const valid = cardValidator.number(cardNumber).isValid;

        if (!valid) {
          this.cardbrandlogo = '';
        }

        return !valid ? { invalidCardNumber: { value: control.value } } : null;
      } catch (error) {
        console.log(error);
        return null;
      }
    };
  }

  private _invalidExpDateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      try {
        if (!this.billingFormGroup) { return null; }
        if (this.billingFormGroup.pristine && !this.billingFormGroup.dirty && !this.billingFormGroup.touched) { return null; }
        const expdate: any = this.billingFormGroup.get('expdate').value;
        const month = parseInt(expdate.split('/')[0], 10);
        const year = parseInt(expdate.split('/')[1], 10);
        const d = Date.parse(`${month}/01/${year}`);
        if (!d) {
          return { invalidDate: { value: control.value } };
        }
        const td = new Date(d);
        td.setMonth(month);
        td.setSeconds(-1);
        const now = new Date().getTime();
        const valid = td.getTime() > now;

        // this.billingFormGroup.patchValue({ expdate: control.value });
        return !valid ? { expiredDate: { value: control.value } } : null;
        // console.log(error);
      } catch (error) {
        console.log(error);
        return null;
      }
    };
  }

  private validateStreet(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      try {
        if (!this.billingFormGroup) { return null; }
        if (this.billingFormGroup.pristine && !this.billingFormGroup.dirty && !this.billingFormGroup.touched) { return null; }

        // remove emoji's
        this.street1.value = this.street1.value.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');

        return { invalidName: { value: control.value } };

      } catch (error) {
        console.log(error);
        return null;
      }
    };
  }

  private validateCity(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      try {
        if (!this.billingFormGroup) { return null; }
        if (this.billingFormGroup.pristine && !this.billingFormGroup.dirty && !this.billingFormGroup.touched) { return null; }

        // remove emoji's
        this.city.value = this.city.value.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');

        return null;

      } catch (error) {
        console.log(error);
        return null;
      }
    };
  }

  private validateEmail(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      try {
        if (!this.billingFormGroup) { return null; }
        if (this.billingFormGroup.pristine && !this.billingFormGroup.dirty && !this.billingFormGroup.touched) { return null; }

        // remove emoji's
        this.email.value = this.email.value.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');

        return null;

      } catch (error) {
        console.log(error);
        return null;
      }
    };
  }

  private _invalidPostalCodeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      try {
        if (this.billingFormGroup.pristine && !this.billingFormGroup.dirty && !this.billingFormGroup.touched) { return null; }
        const postal: any = this.billingFormGroup.get('zip').value.replace(this.regexList.fixCCstring, '');
        const valid = `${postal}`.length === 5;

        this.billingFormGroup.patchValue({ zip: control.value });
        return !valid ? { invalidPostal: { value: control.value } } : null;

      } catch (error) {
        console.log(error);
        return null;
      }
    };
  }

  public formChange(form: any): void {
    const SpaceUnderscoreRegex = /[ ,_]/gi;

    try {
      const nameArray = form.controls.name.value ? form.controls.name.value.split(' ') : [];

      this.cardInfo.addressF = form.controls.street1.value + ', ' + form.controls.city.value + ', ' + form.controls.state.value + ' ' + form.controls.zip.value + ', ' + form.controls.country.value;
      form.controls.addressF.value = this.cardInfo.addressF;

      // console.log(form.controls.expdate.value);
      if (form.controls.expdate.value) {
        const expdate = form.controls.expdate.value.split('/');
        if (expdate.length > 1) {
          if (expdate[1].length === 4) {
            form.controls.expdate.value = expdate[0] + '/' + expdate[1].substr(2);
          }
        }
      }

      // this.street1.value = this.street1.value.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');

      this.billingFormGroup.patchValue({ name: (form.controls.name.value) ? form.controls.name.value.trim() : '' });
      this.billingFormGroup.patchValue({ firstname: nameArray.length > 0 ? nameArray[0] : '' });
      this.billingFormGroup.patchValue({ lastname: nameArray.length > 1 ? nameArray[1] : '' });
      this.billingFormGroup.patchValue({ email: form.controls.email.value.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '') });
      this.billingFormGroup.patchValue({ phone: form.controls.phone.value });
      this.billingFormGroup.patchValue({ cardnumber: form.controls.cardnumber.value });
      this.billingFormGroup.patchValue({ cvv: form.controls.cvv.value });
      this.billingFormGroup.patchValue({ expdate: (form.controls.expdate.value) ? form.controls.expdate.value : '' });
      this.billingFormGroup.patchValue({ cardbrand: form.controls.cardbrand.value });
      this.billingFormGroup.patchValue({ addressF: form.controls.addressF.value });
      this.billingFormGroup.patchValue({ street1: form.controls.street1.value.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '') });
      this.billingFormGroup.patchValue({ city: form.controls.city.value.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '') });
      this.billingFormGroup.patchValue({ state: form.controls.state.value });
      this.billingFormGroup.patchValue({ zip: form.controls.zip.value });

      this.cardInfo.name = this.billingFormGroup.get('name').value;
      this.cardInfo.firstname = this.billingFormGroup.get('firstname').value;
      this.cardInfo.lastname = this.billingFormGroup.get('lastname').value;
      this.cardInfo.cardnumber = this.billingFormGroup.get('cardnumber').value;
      this.cardInfo.cvv = this.billingFormGroup.get('cvv').value;
      this.cardInfo.expdate = this.billingFormGroup.get('expdate').value;
      this.cardInfo.email = this.billingFormGroup.get('email').value ? this.billingFormGroup.get('email').value : '';
      this.cardInfo.phone = this.billingFormGroup.get('phone').value ? this.billingFormGroup.get('phone').value : '';
      this.cardInfo.addressF = this.billingFormGroup.get('addressF').value;
      this.cardInfo.street1 = this.billingFormGroup.get('street1').value;
      this.cardInfo.city = this.billingFormGroup.get('city').value;
      this.cardInfo.state = this.billingFormGroup.get('state').value;
      this.cardInfo.zip = this.billingFormGroup.get('zip').value;
    } catch (error) {
      console.log(error);
    }
  }

  public selectedAddress(addr) {
    clearTimeout(this.searchTimer);

    this.address.geolocation = addr.geometry.location;

    for (let i = 0; i < addr.address_components.length; i++) {
      switch (addr.address_components[i].types[0]) {
        case 'street_number':
          this.cardInfo.street1 = addr.address_components[i].short_name;
          break;
        case 'route':
          this.cardInfo.street1 += ' ' + addr.address_components[i].short_name;
          break;
        case 'locality':
          this.cardInfo.city = addr.address_components[i].short_name;
          break;
        case 'administrative_area_level_1':
          this.cardInfo.state = addr.address_components[i].short_name;
          break;
        case 'postal_code':
          this.cardInfo.zip = addr.address_components[i].short_name;
          break;
        case 'country':
          this.cardInfo.country = addr.address_components[i].short_name || 'USA';
          break;
      }
    }

    this.billingFormGroup.patchValue({ addressF: this.cardInfo.addressF });
    this.billingFormGroup.patchValue({ street1: this.cardInfo.street1 });
    this.billingFormGroup.patchValue({ city: this.cardInfo.city });
    this.billingFormGroup.patchValue({ state: this.cardInfo.state });
    this.billingFormGroup.patchValue({ zip: this.cardInfo.zip });
    this.billingFormGroup.patchValue({ country: this.cardInfo.country });
    this.billingFormGroup.patchValue({ geocode: addr.geometry.location });
  }

  public ccnumChange() {
    let ccnumval = this.billingFormGroup.get('cardnumber').value;
    ccnumval = ccnumval.replace(this.regexList.fixCCstring, '');
    // this.cardnumber.reset(); <- Causing card number delete issue
    const cards = ccType(ccnumval);
    const validator = cardValidator.number(ccnumval);
    const isValid = validator.isValid;

    if (isValid && validator.card && validator.card.type) {
      this.ccRegex.brand = validator.card.type;
      this.billingFormGroup.patchValue({ cardbrand: this.ccRegex.brand });

      switch (validator.card.type) {
        case 'visa':
          this.cardbrandlogo = 'v';
          break;
        case 'mastercard':
          this.cardbrandlogo = 'm';
          break;
        case 'american-express':
          this.cardbrandlogo = 'a';
          break;
        case 'diners-club':
          this.cardbrandlogo = 'dc';
          break;
        case 'discover':
          this.cardbrandlogo = 'd';
          break;
        case 'jcb':
          this.cardbrandlogo = 'jcb';
          break;
        case 'paypal':
          this.cardbrandlogo = 'p';
          break;
        case 'bitcoin':
          this.cardbrandlogo = 'bit';
          break;
        case 'maestro':
          this.cardbrandlogo = 'me';
          break;
        default:
          this.cardbrandlogo = '';
          break;
      }

    } else {
      this.cardbrandlogo = '';
    }

    if (cards && cards.length > 0) {
      const card = cards[0];

      if (validator.card) {
        this.ccRegex.minlength = validator.card.lengths[0];
        this.ccRegex.mincvv = validator.card.code.size;
      } else {
        this.ccRegex.minlength = card.lengths[0];
        this.ccRegex.mincvv = card.code.size;
      }

      this.ccMask = 'X'.repeat(this.ccRegex.minlength - 4) + '0000';
      this.cvvMask = 'X'.repeat(this.ccRegex.mincvv);

      this.cardnumber.setValidators([Validators.required, Validators.minLength(this.ccRegex.minlength), Validators.maxLength(22), this._invalidCardNumberValidator()]);
      this.billingFormGroup.patchValue({ cardnumber: ccnumval });
      this.cardnumber.setValue(ccnumval);

      this.cvv.setValidators([Validators.required, Validators.minLength(this.ccRegex.mincvv), Validators.maxLength(this.ccRegex.mincvv)]);
      this.billingFormGroup.patchValue({ cvv: this.cardInfo.cvv });
      this.cvv.setValue(this.cardInfo.cvv);

      this.ccRegex.cvvmask = 9 * (this.ccRegex.mincvv === 3 ? 111 : 1111);

      this.ccRegex.ccmask = this.regexList.ccRegexList.common;

      // this.cardnumber.setValidators([Validators.required, Validators.minLength(this.ccRegex.minlength), Validators.maxLength(22), this._invalidCardNumberValidator()]);
      // this.billingFormGroup.patchValue({ cardnumber: ccnumval });

      switch (card.type) {
        case 'visa':
          this.ccRegex.brand = 'visa';
          break;
        case 'mastercard':
          this.ccRegex.brand = 'mastercard';
          break;
        case 'american-express':
          this.ccRegex.brand = 'american-express';
          this.ccRegex.ccmask = this.regexList.ccRegexList.amex;
          // this.minCVVLength = 3;
          break;
        case 'diners-club':
          this.ccRegex.brand = 'diners';
          if (card.lengths[0] <= 14) {
            this.ccRegex.ccmask = this.regexList.ccRegexList.diners_inter;
          }
          break;
        case 'discover':
          this.ccRegex.brand = 'discover';
          break;
        case 'jcb':
          this.ccRegex.brand = 'jcb';
          break;
        case 'unionpay':
          this.ccRegex.brand = 'unionpay';
          if (card.lengths[0] === 19) {
            this.ccRegex.ccmask = this.regexList.ccRegexList.unionpay19;
          } else if (card.lengths[0] === 17) {
            this.ccRegex.ccmask = this.regexList.ccRegexList.unknown17;
          } else if (card.lengths[0] === 18) {
            this.ccRegex.ccmask = this.regexList.ccRegexList.unknown18;
          }
          break;
        case 'maestro':
          this.ccRegex.brand = 'maestro';
          if (card.lengths[0] === 13) {
            this.ccRegex.ccmask = this.regexList.ccRegexList.maestro13;
          } else if (card.lengths[0] === 15) {
            this.ccRegex.ccmask = this.regexList.ccRegexList.maestro15;
          } else if (card.lengths[0] === 16) {
            this.ccRegex.ccmask = this.regexList.ccRegexList.common;
          } else if (card.lengths[0] === 19) {
            this.ccRegex.ccmask = this.regexList.ccRegexList.maestro19;
          }
          break;
        default:
          this.ccRegex.brand = undefined;
          break;
      }

    }
  }

  /*   public ccKeyPress(keyevent): boolean {
      try {
        const char = (keyevent.which) ? keyevent.which : keyevent.keyCode;
      } catch (error) {
        console.log(error);
      }
    } */

  public addressLookUp(): void {
    clearTimeout(this.searchTimer);

    this.searchTimer = setTimeout(() => {
      this.google.search(this.billingFormGroup.get('street1').value).subscribe((d: any) => {
        this.matches = d;
      }, error => {
        console.log('error:', error);
      });
    }, 1000);
  }

  public async GuestLogin(): Promise<User> {
    return new Promise(async (resolve, reject) => {
      try {

        const credentials = new LogIn();
        credentials.email = 'guest@electronicpayments.com';
        credentials.password = '';
        credentials.application = 'deliverme';

        this.authSvc.guestLogin(credentials).subscribe(async (data) => {
          if (data && data.access_token) {
            this.user = data;
            // this.localStorage.set('user', this.user).subscribe(() => { });
            localStorage.setItem('token', data.access_token); // this is not the same as 'this.localStorage'
            this.localStorage.set('token', data.access_token).subscribe(() => { });
            this.localStorage.set('user', data).subscribe();
            await this.authSvc.SetAuthStateAsync(this.user);
            resolve(data);
          } else {
            this.localStorage.delete('token').subscribe(() => { });
            localStorage.delete('token');
            reject({ message: 'Authorization token not generated' });
          }
        }, err => {
          if (err.error) {
            reject(err.error);
          } else {
            reject(err);
          }
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  clearCache(): void {
    try {
      this.localStorage.delete(this.merchant._id).subscribe();
      this.localStorage.delete('order').subscribe(() => { });
      this.localStorage.delete('schedule').subscribe(() => { });
      this.localStorage.delete('deliveryinfo').subscribe(() => { });
      this.localStorage.delete('paymethod').subscribe(() => { });
      this.localStorage.delete('tip').subscribe(() => { });
      this.localStorage.delete('cart').subscribe(() => { });
      this.localStorage.delete('user').subscribe(() => { });
      this.localStorage.clear().subscribe();
    } catch (error) {
      console.log(error);
    }
  }

  async processPayment(stepper: MatStepper): Promise<void> {
    try {

      if (this.billingFormGroup.invalid) {
        this.processingPayment = false;
        return;
      }

      this.processingPayment = true;

      const guestuser = await this.GuestLogin();
      this.user.access_token = guestuser.access_token;

      const transaction: Transaction = new Transaction();
      transaction.cardNotPresent = true;
      transaction.isProcharge = true;
      transaction.transactionCode = '1';
      transaction.applicationKey = this.merchant.applicationKey;
      transaction.merchantNumber = this.merchant.merchantNumber1;
      transaction.name = this.name.value;
      transaction.firstName = this.name.value.split(' ')[0];
      transaction.lastName = this.name.value.split(' ')[1];
      transaction.street1 = this.street1.value;
      transaction.street2 = '';
      transaction.city = this.city.value;
      transaction.state = this.state.value;
      transaction.postalCode = this.zip.value;
      transaction.country = this.country.value;
      transaction.phone = this.phone.value;
      transaction.cell = this.phone.value;
      transaction.email = this.email.value;
      transaction.companyName = this.merchant.businessName;
      transaction.trackData = '';
      transaction.cardNumber = this.cardnumber.value;
      transaction.ccExpMonth = this.expdate.value.split('/')[0];
      transaction.ccExpYear = this.expdate.value.split('/')[1];
      transaction.cvv = this.cvv.value;
      transaction.ccType = this.cardbrand.value;
      transaction.amount = this.totalAmount;
      transaction.tipAmount = this.tipAmount;
      transaction.taxRate = this.merchant.settings.taxrate.toFixed(2);
      transaction.taxAmount = this.order.tax.toString();
      transaction.orderNumber = this.order.orderno.toString();
      transaction.merchantid = this.merchant.merchantid;
      transaction.profileid = this.merchant.profileid;
      transaction.ccLastFour = this.cardnumber.value.substr(this.cardnumber.value.length - 4, 4);
      transaction.source = 'dm';

      if (this.paymentSubscription) {
        this.paymentSubscription.unsubscribe();
      }

      this.paymentSubscription = this.orderService.submitTransaction(transaction, guestuser.access_token).subscribe((response: EtcPaymentResponse) => {
        this.paymentResponse = response;

        const payment: Payment = new Payment();
        payment.name = transaction.name;
        payment.firstname = transaction.firstName;
        payment.lastname = transaction.lastName;
        payment.cardnumber = transaction.cardNumber.substr(0, 1) + '*'.repeat(transaction.cardNumber.length - 5) + transaction.cardNumber.substr(transaction.cardNumber.length - 4, 4);
        payment.cc_exp_month = '**';
        payment.cc_exp_year = '**';
        payment.cvv = '*'.repeat(transaction.cvv.length);
        payment.cc_type = transaction.ccType;
        payment.amount = parseFloat(transaction.amount);
        payment.cc_last_four = transaction.ccLastFour;
        payment.address1 = transaction.street1;
        payment.city = transaction.city;
        payment.state = transaction.state;
        payment.postalcode = transaction.postalCode;
        payment.country = transaction.country;
        payment.transactionIdentifer = response.TransactionIdentifier || response.TransactionIdentifer;
        payment.batchNumber = response.BatchNumber.toString();
        payment.itemNumber = response.ItemNumber.toString();
        payment.revisionNumber = response.RevisionNumber.toString();
        payment.paymentId = response.PaymentId;
        payment.response.push(response);

        this.order.customerInformation = new Customer();
        this.order.customerInformation.name = payment.name;
        this.order.customerInformation.firstName = payment.firstname;
        this.order.customerInformation.lastName = payment.lastname;
        this.order.customerInformation.email = transaction.email;
        this.order.customerInformation.phone = transaction.phone;
        this.order.customerInformation.street1 = payment.address1;
        this.order.customerInformation.street2 = '';
        this.order.customerInformation.city = payment.city;
        this.order.customerInformation.state = payment.state;
        this.order.customerInformation.postalcode = payment.postalcode;
        this.order.customerInformation.country = payment.country || 'USA';

        this.order.businessName = this.merchant.businessName;
        const tzDate = new Date(this.order.datetime).toString();
        this.orderDateTime = moment(tzDate).format('MMMM Do YYYY, h:mm:ss a');

        this.order.requestID = uuid();
        this.order.ordertype = OrderTypeEnum.DineIn;
        this.order.applicationKey = this.merchant.applicationKey;
        this.order.orderstatus = OrderStatus.Completed;
        this.order.paymethods = [];
        this.order.paymethods.push(payment);
        this.order.ScanToPay = true;
        this.order.InvoiceId = response.InvoiceId;
        this.order.InvoiceNum = response.InvoiceNum;
        this.order.merchantNumber = this.merchant.merchantNumber1;

        if (response.ResponseCode === 0) {
          this.orderSubscription = this.orderService.submitOrder(this.order).subscribe((confirm: OrderConfirmation) => {
            this.order.receiptNumber = confirm.order.receiptNumber;
            this.order.orderxref = confirm.order.orderxref;
            this.processingPayment = false;
            stepper.next();
          }, (error) => {
            console.log(error);
            this.processingPayment = false;
            this.orderSubmitError = error;
            stepper.next();
          });
        } else {
          this.processingPayment = false;
          stepper.next();
        }
      }, (error: any) => {
        console.log(error);
        this.processingPayment = false;
        this.paymentResponse = error;
        stepper.next();
      });

    } catch (error) {
      this.processingPayment = false;
      console.log(error.message);
      // alert(error.message);
    }
  }

  public async submitPayment(mode: string, stepper: MatStepper): Promise<void> {

    let logEntry = new LogEntry();

    try {

      logEntry.entry.application = 'dm-ui';
      logEntry.entry.file = 'scan-to-pay.component.ts';
      logEntry.entry.method = 'submitPayment';
      logEntry.entry.merchantNumber = this.merchantNumber;

      this.disableApplePay = true;
      this.processingPayment = true;

      this.order.applicationKey = this.merchant.applicationKey;
      this.order.merchantNumber = this.merchant.merchantNumber1;
      this.order.businessName = this.merchant.businessName;
      this.order.requestID = uuid();

      this.order.userid = this.user._id;
      this.order.datetime = moment(new Date()).format('LLL');
      this.order.asap = true;

      if (!this.order.customerInformation) {
        this.order.customerInformation = new Customer();
      }

      this.order.ordertype = OrderTypeEnum.DineIn;
      this.order.taxrate = this.merchant.settings.taxrate;
      this.order.ScanToPay = true;

      try {
        this.order.tip = parseFloat(this.tipAmount);
        this.order.totalAmount = parseFloat(this.totalAmount);
        this.order.taxrate = this.merchant.settings.taxrate;
      } catch (e) {
        console.log(e);
        this.disableApplePay = false;
        return;
      }

      let result: any;

      switch (mode) {
        case 'a':
          this.applePay = new ApplePay(this.order, this.http, this.orderService, this.applePayConfig);
          // await this.applePay.displayPaymentSheet();
          result = await this.applePay.displayPaymentSheet().catch((err: any) => {

            this.orderSubmitError = err;

            logEntry.entry.error = 'displayPaymentSheet(): ' + JSON.stringify(err);

            if (this.loggerSubscription) {
              this.loggerSubscription.unsubscribe();
            }

            this.loggerSubscription = this.loggerService.writeToLog(logEntry).subscribe((response: any) => {
            }, (error: any) => {
              console.log('log service error: ' + error.message);
            });

            let error: any;

            if (err.error) {
              error = err.error;
            } else {
              error = err;
            }

            if (error) {

              this.paymentResponse = (error.paymentResponse) ? error.paymentResponse : undefined;

              let msg = error.message;
              let details: string;

              if (error.paymentResponse && error.paymentResponse.ResponseCode === 1) {
                msg = 'There was an error processing your payment';
                details = error.paymentResponse.ResponseText + '<br>' + error.paymentResponse.AVSResponseText + '<br>' + error.paymentResponse.CVVResponseText;
              } else if (error.paymentResponse && error.paymentResponse.ResponseCode === 0) {
                msg = 'There was an error processing your order. Please call ' + this.merchant.phone + " to confirm order.";
                details = error.paymentResponse.ResponseText + '<br>' + error.paymentResponse.AVSResponseText + '<br>' + error.paymentResponse.CVVResponseText;
              }
            }
          });
          break;
        case 'g':
          break;
        case 's':
          break;
      }

      logEntry.entry.text = 'result: ' + JSON.stringify(result);

      this.loggerSubscription = this.loggerService.writeToLog(logEntry).subscribe((response: any) => {
      }, (error: any) => {
        console.log('log service error: ' + error.message);
      });

      if (result) {
        // clearInterval(this.timer);
        let message = {};
        let mMessage = {};

        if (!this.baseUrl) {
          this.baseUrl = 'https://www.deliverme.com';
        }

        this.paymentResponse = result.paymentResponse;

        if (result.confirmation) {

          result.order.orderxref = result.confirmation;

          if (this.user.isAutoNotification) {
            if (result.order && result.order.receiptNumber) {
              message = {
                userid: this.user._id,
                merchantid: this.merchant._id,
                to_numbers: [result.order.customerInformation.phone],
                storename: this.merchant.businessName,
                type: 0,
                message: this.merchant.businessName + ' - We received your order for $' + this.order.totalAmount.toFixed(2) +
                  '\r\nYour confirmation number is ' + result.confirmation + '\n' +
                  result.message.replace('<br>', '\n') + '\n' +
                  'Click here to view your receipt: ' + this.baseUrl + '/receipt/' + result.order.receiptNumber
              };

              mMessage = {
                userid: this.user._id,
                merchantid: this.merchant._id,
                to_numbers: [(this.merchant.contactInfo && this.merchant.contactInfo.phone) ? this.merchant.contactInfo.phone : this.merchant.phone],
                storename: this.merchant.businessName,
                type: 0,
                message: 'Order #' + result.order.orderxref + ' has been received for ' + OrderTypeEnum[result.order.ordertype] + '\n' +
                  'Click here to view order receipt: ' + this.baseUrl + '/merchant-receipt/' + result.order.receiptNumber
              };
            } else {
              message = {
                userid: this.user._id,
                merchantid: this.merchant._id,
                to_numbers: [result.order.customerInformation.phone.replace(/[^0-9]/g, '')],
                storename: this.merchant.businessName,
                type: 0,
                message: this.merchant.businessName + ' - We received your order for $' + this.order.totalAmount.toFixed(2) +
                  '\r\nYour confirmation number is ' + result.confirmation + '\n' +
                  result.message.replace('<br>', '\n')
              };

              mMessage = {
                userid: this.user._id,
                merchantid: this.merchant._id,
                to_numbers: [(this.merchant.contactInfo && this.merchant.contactInfo.phone) ? this.merchant.contactInfo.phone : this.merchant.phone],
                storename: this.merchant.businessName,
                type: 0,
                message: 'Order #' + result.order.orderxref + ' has been received for ' + result.order.ordertype
              };
            }

            if (this.smsSubscription) {
              this.smsSubscription.unsubscribe();
            }

            if (this.merchantSmsSubscription) {
              this.merchantSmsSubscription.unsubscribe();
            }

            logEntry.entry.text = 'Send SMS Message';

            this.loggerSubscription = this.loggerService.writeToLog(logEntry).subscribe((response: any) => {
            }, (error: any) => {
              console.log('log service error: ' + error.message);
            });

            this.smsSubscription = this.smsSvc.sendMessage(message).subscribe(_ => {
              if (!this.merchant.contactInfo ||
                this.merchant.contactInfo.contactMethod === null ||
                this.merchant.contactInfo.contactMethod === undefined ||
                this.merchant.contactInfo.contactMethod === 1 ||
                this.merchant.contactInfo.contactMethod === 3) {
                this.merchantSmsSubscription = this.smsSvc.sendMessage(mMessage).subscribe();
              }
            });
          }

          if (Array.isArray(result.order.paymethods)) {
            if (!Array.isArray(result.order.paymethods[0].response) || result.order.paymethods[0].response.length === 0) {
              result.order.paymethods[0].response = [];
              result.order.paymethods[0].response.push(result.paymentResponse);
            }
          }

          logEntry.entry.text = 'sendEmailConfirmation';

          this.loggerSubscription = this.loggerService.writeToLog(logEntry).subscribe((response: any) => {
          }, (error: any) => {
            console.log('log service error: ' + error.message);
          });

          await this.sendEmailConfirmation(result.order);

          this.clearCache();

        } else {

          if (result.order && result.order.receiptNumber) {
            message = {
              userid: this.user._id,
              merchantid: this.merchant._id,
              to_numbers: [this.order.customerInformation.phone],
              storename: this.merchant.businessName,
              type: 0,
              message: this.merchant.businessName + ' - Order Total $' + this.order.totalAmount.toFixed(2) +
                '\r\nYour confirmation number is ' + result.confirmation + '\n' +
                result.message + '\n' +
                'Click here to view your receipt: ' + this.baseUrl + '/receipt/' + result.order.receiptNumber
            };

            logEntry.entry.text = 'Send SMS Message 2';

            this.loggerSubscription = this.loggerService.writeToLog(logEntry).subscribe((response: any) => {
            }, (error: any) => {
              console.log('log service error: ' + error.message);
            });

            this.smsSvc.sendMessage(message).subscribe();

            if (Array.isArray(result.order.paymethods)) {
              if (!Array.isArray(result.order.paymethods[0].response) || result.order.paymethods[0].response.length === 0) {
                result.order.paymethods[0].response = [];
                result.order.paymethods[0].response.push(result.paymentResponse);
              }
            }

            //  alert(JSON.stringify(result.order.paymethods));

            logEntry.entry.text = 'Send Email Confirmation 2';

            this.loggerSubscription = this.loggerService.writeToLog(logEntry).subscribe((response: any) => {
            }, (error: any) => {
              console.log('log service error: ' + error.message);
            });

            result.order.orderxref = result.confirmation;
            await this.sendEmailConfirmation(result.order);

            this.clearCache();

          } else {
            if (result.message) {

              let errorMsg = result.message;
              let msg = 'There was an error processing your payment';
              let details: string;

              this.orderSubmitError = { "message": this.paymentResponse.ResponseText };

              if (result.paymentResponse && result.paymentResponse.ResponseCode === 1) {
                // msg = 'There was an error processing your payment';
                details = result.paymentResponse.ResponseText + '<br>' + result.paymentResponse.AVSResponseText + '<br>' + result.paymentResponse.CVVResponseText;
              }

              // alert(msg);
              // const dref = this.confirmDialog.open(ConfirmDialogComponent, { data: { message: msg, text: details } });

            } else {

            }
          }
        }

      } else {

      }

      // stepper.next();
      stepper.selectedIndex = 4;

      this.processingEmail = false;
      this.processingSMS = false;
      this.processingPayment = false;
      this.disableApplePay = false;

    } catch (error) {
      // console.log(error);
      this.processingPayment = false;
      this.disableApplePay = false;
      this.processingEmail = false;
      this.processingSMS = false;
    }
  }

  addRating(value: number): void {
    try {
      if (this.ratingSubscription) {
        this.ratingSubscription.unsubscribe();
      }

      this.disableRatings = true;

      const businessRating = new Rating();
      businessRating.merchantid = this.merchant._id;
      businessRating.rating = value;
      businessRating.receiptNumber = this.order.receiptNumber;
      businessRating.userid = (this.order.userid) ? this.order.userid : '';
      businessRating.comment = '';

      this.ratingSubscription = this.ratingsService.addRating(businessRating).subscribe(() => {
        this.disableRatings = false;
      }, (error => {
        console.log(error.message);
        this.disableRatings = false;
      }));

    } catch (error) {
      console.log(error.message);
      this.disableRatings = false;
    }
  }

  public onRatingChange(event: any): void {
    // console.log(event);
    this.addRating(event);
  }

  async sendEmailConfirmation(order: Order): Promise<any> {
    return new Promise(async (resolve, reject) => {

      let logEntry = new LogEntry();

      try {

        logEntry.entry.application = 'dm-ui';
        logEntry.entry.file = 'scan-to-pay.component.ts';
        logEntry.entry.method = 'sendEmailConfirmation';
        logEntry.entry.merchantNumber = this.merchant.merchantNumber1;

        this.processingEmail = true;

        const email: EmailOrderConfirmation = new EmailOrderConfirmation();
        const template: EmailOrderTemplate = new EmailOrderTemplate();

        template.viewonline = this.baseUrl + '/receipt/' + order.receiptNumber;
        template.merchantlogo = this.merchant.logo;
        template.businessname = this.merchant.businessName;
        template.merchantstreet = this.merchant.address;
        template.merchantcity = this.merchant.city;
        template.merchantstate = this.merchant.state;
        template.merchantzipcode = this.merchant.zip;
        template.merchantphone = this.merchant.phone;
        template.orderno = order.orderxref;
        template.items = order.items;

        for (const item of template.items) {
          item.labeledPrice = item.price.toFixed(2);

          for (const side of item.sides) {
            side.price = side.price.toFixed(2);
          }

          for (const option of item.options) {
            option.price = option.price.toFixed(2);
          }
        }

        template.subtotal = order.subtotal.toFixed(2);
        template.servicefee = (order.serviceFee > 0) ? order.serviceFee.toFixed(2) : '';
        template.tax = order.tax.toFixed(2);
        template.gratuity = order.tip.toFixed(2);
        template.deliveryfee = (order.deliveryFee > 0) ? order.deliveryFee.toFixed(2) : '';
        template.totalamount = order.totalAmount.toFixed(2);
        template.ordertype = 'Dine In';
        template.customername = this.name.value;
        template.orderdatetime = order.datetime || moment.utc(new Date().toUTCString()).local().format('LLL');

        template.locationstreet = this.merchant.address;
        template.locationcity = this.merchant.city;
        template.locationstate = this.merchant.state;
        template.locationzip = this.merchant.zip;
        template.locationphone = this.merchant.phone;
        template.locationemail = this.merchant.email;

        switch (this.cardbrand.value.toLowerCase()) {
          case 'vi':
          case 'visa':
            template.cardbrandlogo = 'v';
            break;
          case 'mc':
          case 'mastercard':
            template.cardbrandlogo = 'm';
            break;
          case 'ax':
          case 'amex':
          case 'american-express':
            template.cardbrandlogo = 'a';
            break;
          case 'diners-club':
            template.cardbrandlogo = 'dc';
            break;
          case 'di':
          case 'discover':
            template.cardbrandlogo = 'd';
            break;
          case 'jcb':
            template.cardbrandlogo = 'jcb';
            break;
          case 'paypal':
            template.cardbrandlogo = 'p';
            break;
          case 'bitcoin':
            template.cardbrandlogo = 'bit';
            break;
          case 'maestro':
            template.cardbrandlogo = 'me';
            break;
          default:
            break;
        }

        logEntry.entry.text = 'loading email template';

        if (this.loggerSubscription) {
          this.loggerSubscription.unsubscribe();
        }

        this.loggerSubscription = this.loggerService.writeToLog(logEntry).subscribe((response: any) => {
        }, (error: any) => {
          console.log('log service error: ' + error.message);
        });

        template.cclastfour = order.paymethods[0].cc_last_four;
        template.cardholder = this.name.value;
        template.paymentdatetime = moment.utc(order.paymethods[0].datetime).local().format('LLL');
        template.paymentid = (order.paymethods[0].response && order.paymethods[0].response[0].PaymentId) ? order.paymethods[0].response[0].PaymentId.toString() : '0';
        template.approvalcode = order.paymethods[0].response[0].AuthorizationNumber;
        template.transactionidentifer = order.paymethods[0].transactionIdentifer || order.paymethods[0].transactionIdentifier;
        template.invoicenum = order.paymethods[0].response[0].InvoiceNum || 'INV-ABC123';
        template.productlogo = 'https://epionlineorders.s3.us-east-2.amazonaws.com/assets/images/deliverme_logo.png';
        template.copyrightyear = new Date().getFullYear().toString();

        email.Source = 'noreply@deliverme.com';
        email.Template = 'epi_receipt';
        email.Destination.ToAddresses = [order.customerInformation.email];
        email.Destination.CcAddresses = [];
        email.TemplateData = JSON.stringify(template);

        if (this.emailSubscription) {
          this.emailSubscription.unsubscribe();
        }

        logEntry.entry.text = 'submitting email receipt';

        if (this.loggerSubscription) {
          this.loggerSubscription.unsubscribe();
        }

        this.loggerSubscription = this.loggerService.writeToLog(logEntry).subscribe((response: any) => {
        }, (error: any) => {
          console.log('log service error: ' + error.message);
        });

        this.emailSubscription = this.emailService.sendEmailConfirmation(email).subscribe((result: any) => {
          alert('email sent');
          this.processingEmail = false;
          resolve(result);
        }, (error: any) => {
          // alert('scan-to-pay.components.sendEmailConfirmation: ' + error.message);
          this.processingEmail = false;
          reject(error);
        });

      } catch (error) {
        // alert('scan-to-pay.components.sendEmailConfirmation: error: ' + error.message);
        this.processingEmail = false;
        reject(error);
      }
    });
  }

  async emailReceipt(): Promise<void> {
    try {
      await this.sendEmailConfirmation(this.order);
      this.emailSent = true;
      alert('A copy of this receipt has been sent to your email');
      this.processingEmail = false;
    } catch (error) {
      this.processingEmail = false;
      alert(error.message);
    }
  }

  textReceipt(): void {
    try {

      this.processingSMS = true;

      let message = {};

      if (this.order.receiptNumber) {
        message = {
          userid: this.user._id,
          merchantid: this.merchant._id,
          to_numbers: [this.phone.value.replace(/[^0-9]/g, '')],
          storename: this.merchant.businessName,
          type: 0,
          message: 'Receipt from ' + this.merchant.businessName + '\r\n' +
            'Amount Paid: $' + this.order.totalAmount.toFixed(2) + '\r\n' +
            'Date Paid: ' + moment.utc(new Date().toUTCString()).local().format('LLL') + '\r\n' +
            'Pay Method: ' + this.cardbrand.value + ' - ' + this.cardnumber.value.substr(this.cardnumber.value.length - 4, 4) + '\r\n' +
            'Click here to view your receipt: ' + this.baseUrl + '/receipt/' + this.order.receiptNumber + '\r\n'
        };
      } else {
        message = {
          userid: this.user._id,
          merchantid: this.merchant._id,
          to_numbers: [this.order.customerInformation.phone.replace(/[^0-9]/g, '')],
          storename: this.merchant.businessName,
          type: 0,
          message: 'Receipt from ' + this.merchant.businessName + '\r\n' +
            'Amount Paid: $' + this.order.totalAmount.toFixed(2) + '\r\n' +
            'Date Paid: ' + moment.utc(new Date().toUTCString()).local().format('LLL') + '\r\n' +
            'Pay Method: ' + this.cardbrand.value + ' - ' + this.cardnumber.value.substr(this.cardnumber.value.length - 4, 4) + '\r\n'
        };
      }

      this.smsSvc.sendMessage(message).subscribe();

      this.smsSent = true;
      alert('SMS receipt sent');
      this.processingSMS = false;

    } catch (error) {
      this.processingSMS = false;
      alert(error.message);
    }
  }

  public done(): void {
    try {
      this.router.navigate([this.merchant.routeName]);
    } catch (error) {
      alert(error.message);
    }
  }
}
